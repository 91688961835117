<template>
  <elevator-properties :liftpropertytype="1"></elevator-properties>
</template>
<script>
import { getItemFromArrayByKey } from 'U'
import store from '@/store'
import elevatorProperties from 'V/facilitiesEquipment/elevator/elevatorProperties/Index.vue'
export default {
  name: 'elevatorProperties1',
  components: {
    elevatorProperties,
  },
  beforeRouteEnter(to, from, next) {
    let toTab = getItemFromArrayByKey(store.state.main.tabs, 'id', to.name);
    if(!toTab || from.name != 'elevatorProperties1') {
      store.commit('delKeepAlive', to.name)
    }
    next()
  },
}
</script>